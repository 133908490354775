

























































import { VueConstructor } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ConjunctionOperator, IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName, DashmixTheme } from '@movecloser/ui-core'

import { Inject } from '@modules'
import { Modals } from '@/config/modals'

import { FormCheckbox, FormInput } from '@component/form'
import { Loader } from '@component/Loader/Loader'

import { CreateSetIntentionPayload } from '../intentions/CreateSetIntention'
import { EditSetIntentionPayload } from '../intentions/EditSetIntention'
import { ResetListItemsModalPayload, SetProperties, SetSource, SetType } from '../contracts'
import { sourceComponentConfig } from '../maps/sets'
import { SetModal } from '../config/modals'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SetForm>({
  name: 'SetForm',
  components: { Loader, FormCheckbox, FormInput },

  created () {
    this.conjunction = this.payload.properties?.conjunction || ConjunctionOperator.And
    this.sources = this.payload.properties?.src || []
    this.title = this.payload.title || ''
  }
})
export class SetForm extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public editable!: boolean

  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: true, default: false })
  public isEditMode!: boolean

  @Prop({ type: Boolean, required: false })
  public isLoading!: boolean

  @Prop({ type: Boolean, required: true })
  public isSaving!: boolean

  @Prop({ type: Object, required: true })
  public payload!: CreateSetIntentionPayload | EditSetIntentionPayload

  @Prop({ type: String, required: false, default: '' })
  public setName!: string

  @Prop({ type: String, required: true })
  public setType!: SetType

  @Inject(ModalType)
  private modalConnector!: IModal

  public buttonTheme = DashmixTheme
  public conjunction: ConjunctionOperator = ConjunctionOperator.And
  public Icons = DashmixIconName
  public shouldRefresh: boolean = false
  public sources: SetSource[] = [{ type: '', value: '' }]
  public title?: string = ''

  public get formName (): string {
    switch (this.isEditMode) {
      case true:
        return 'editSet'
      case false:
        return 'createSet'
    }
  }

  public get formTitle (): string {
    switch (this.isEditMode) {
      case true:
        return `${this.$t(`sets.edit.form.header.${this.setType}`, { title: this.setName })}`
      case false:
        return `${this.$t(`sets.create.form.header.${this.setType}`)}`
    }
  }

  public get sourceComponent (): VueConstructor | null {
    if (typeof sourceComponentConfig[this.setType] === 'undefined') {
      throw new Error(`[SetForm] No row component register for [${this.setType}]`)
    }
    return sourceComponentConfig[this.setType]
  }

  public get shouldEnableRefresh () {
    return this.setType !== SetType.Question
  }

  public onConjunctionChange (value: ConjunctionOperator) {
    this.conjunction = value
    this.payload.properties = { ...this.payload.properties, conjunction: value }
  }

  public onSourcesChange (value: SetSource[]) {
    this.sources = value
    const payload = this.payload
    payload.properties = this.composeProperties()
    this.$emit('sources-updated', payload)
  }

  public onTitleChange (value: string) {
    this.title = value
    this.$emit('title-updated', this.title)
  }

  public toggleReset (value: boolean) {
    this.$emit('reset', value)
  }

  public toggleResort (value: boolean) {
    this.$emit('resort', value)
  }

  protected composeProperties (): SetProperties {
    return {
      locked: this.payload.properties.locked,
      src: this.sources,
      conjunction: this.conjunction
    }
  }

  protected openResetModal () {
    const payload: ResetListItemsModalPayload = {
      list: this.setName,
      onClose: () => this.modalConnector.close(),
      onConfirm: () => {
        this.$emit('reset', true)
        this.modalConnector.close()
      }
    }

    this.modalConnector.open(SetModal.ResetListItemsModal, payload, { closableWithOutsideClick: true })
  }
}

export default SetForm
