// Copyright © 2021 Move Closer

import { ModuleDriver } from '@modules'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const pageModules: string[] = [
  // Basic content modules.
  ModuleDriver.Text,
  ModuleDriver.Heading,
  ModuleDriver.Image,
  ModuleDriver.Button,

  // Other important modules.
  ModuleDriver.Card,
  ModuleDriver.Tile,
  ModuleDriver.Labels,
  ModuleDriver.Mosaic,
  ModuleDriver.Quote,
  ModuleDriver.Stats,

  // Other modules.
  ModuleDriver.Hero,
  ModuleDriver.Carousel,
  ModuleDriver.ContactPerson,
  ModuleDriver.Tabs,
  ModuleDriver.Newsletter,
  ModuleDriver.Timeline,
  ModuleDriver.BlogFeed,
  ModuleDriver.JobOffers,
  ModuleDriver.Departments,
  ModuleDriver.HashtagWall,
  ModuleDriver.Question,

  // Less important modules.
  ModuleDriver.GlobalSearchResults,
  ModuleDriver.SearchResults,
  ModuleDriver.FloatingButton,

  ModuleDriver.Skiplinks,
  ModuleDriver.Embed
]
