// Copyright © 2021 Move Closer

import { DashmixIconName, TableHead, TableSearchBarAction, DashmixTheme } from '@movecloser/ui-core'
import { VueConstructor } from 'vue'

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import {
  canDeleteBlogPostsFeed, canDeleteJobsFeed,
  canEditBlogPostsFeed, canEditJobsFeed
} from '../config/guards'
import { ModelsConfig } from '../contracts/data'
import { OffersItem } from '../models/offers-item'
import OffersSetItem from '../components/SetItem/OffersSetItem.vue'
import { offersItemAdapterMap } from '../models/offers-item.adapter'
import { PostsItem } from '../models/posts-item'
import { postsItemAdapterMap } from '../models/posts-item.adapter'
import PostSetItem from '../components/SetItem/BlogPostsSetItem.vue'
import { SetType } from '../contracts'
import SetTableRow from '../components/SetTableRow.vue'
import SourcesForm from '../components/SourcesForm.vue'
import { SocialMediaItem } from '../models/social-media-item'
import SocialPostsSetItem from '../components/SetItem/SocialPostsSetItem.vue'
import QuestionSetItem from '../components/SetItem/QuestionSetItem.vue'
import { socialMediaItemAdapterMap } from '../models/social-media-item.adapter'
import { QuestionItem } from '../models/question-item'
import { questionItemAdapterMap } from '../models/question-item.adapter'

export const setsTableHead: TableHead = [
  {
    column: 'name',
    label: 'Nazwa',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'author',
    label: 'Autor',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'setsNumber',
    label: 'Ilość elementów',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'strategy',
    label: 'Strategia',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'createdDate',
    label: 'Data utworzenia',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'updatedAt',
    label: 'Data modyfikacji',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]

export enum SetsListsActions {
  Delete = 'delete',
  Generate = 'generate',
  Preview = 'preview'
}

export const itemComponentConfig: Record<SetType, VueConstructor> = {
  [SetType.JobOffers]: OffersSetItem,
  [SetType.BlogPosts]: PostSetItem,
  [SetType.SocialPosts]: SocialPostsSetItem,
  [SetType.Question]: QuestionSetItem
}

export const rowComponentConfig: Record<SetType, VueConstructor> = {
  [SetType.JobOffers]: SetTableRow,
  [SetType.BlogPosts]: SetTableRow,
  [SetType.SocialPosts]: SetTableRow,
  [SetType.Question]: SetTableRow
}

export const sourceComponentConfig: Record<SetType, VueConstructor | null> = {
  [SetType.JobOffers]: SourcesForm,
  [SetType.BlogPosts]: SourcesForm,
  [SetType.SocialPosts]: null,
  [SetType.Question]: null
}

export const itemsModelConfig: Record<SetType, ModelsConfig> = {
  [SetType.JobOffers]: {
    useAdapter: true,
    model: OffersItem,
    adapterMap: offersItemAdapterMap
  },
  [SetType.BlogPosts]: {
    useAdapter: true,
    model: PostsItem,
    adapterMap: postsItemAdapterMap
  },
  [SetType.SocialPosts]: {
    useAdapter: true,
    model: SocialMediaItem,
    adapterMap: socialMediaItemAdapterMap
  },
  [SetType.Question]: {
    useAdapter: true,
    model: QuestionItem,
    adapterMap: questionItemAdapterMap
  }
}

export const setsRowActionsFactory = (domain: Domain, user: UserModel | null, setType: SetType): TableSearchBarAction[] => {
  const actions = []

  switch (setType) {
    case SetType.BlogPosts:
      if (canEditBlogPostsFeed(domain, user)) {
        actions.push(
          {
            label: 'Przeglądaj',
            icon: DashmixIconName.EyeSolid,
            isBulkAction: false,
            action: SetsListsActions.Preview
          }
        )
      }

      if (canDeleteBlogPostsFeed(domain, user)) {
        actions.push(
          {
            label: 'Usuń',
            icon: DashmixIconName.TrashAltSolid,
            isBulkAction: true,
            action: SetsListsActions.Delete,
            theme: DashmixTheme.Danger
          }
        )
      }
      break
    case SetType.JobOffers:
      if (canEditJobsFeed(domain, user)) {
        actions.push(
          {
            label: 'Przeglądaj',
            icon: DashmixIconName.EyeSolid,
            isBulkAction: false,
            action: SetsListsActions.Preview
          }
        )
      }

      if (canDeleteJobsFeed(domain, user)) {
        actions.push(
          {
            label: 'Usuń',
            icon: DashmixIconName.TrashAltSolid,
            isBulkAction: true,
            action: SetsListsActions.Delete,
            theme: DashmixTheme.Danger
          }
        )
      }
      break
    case SetType.SocialPosts:
      // TODO - add permissions when API ready
      // if (canEditJobsFeed(domain, user)) {
      //   actions.push(
      //     {
      //       label: 'Przeglądaj',
      //       icon: DashmixIconName.EyeSolid,
      //       isBulkAction: false,
      //       action: SetsListsActions.Preview
      //     }
      //   )
      // }
      //
      // if (canDeleteJobsFeed(domain, user)) {
      //   actions.push(
      //     {
      //       label: 'Usuń',
      //       icon: DashmixIconName.TrashAltSolid,
      //       isBulkAction: true,
      //       action: SetsListsActions.Delete,
      //       theme: DashmixTheme.Danger
      //     }
      //   )
      // }
      // break
  }

  return actions
}
