// Copyright © 2021 Move Closer

import { Permission } from '@module/auth/contracts/permissions'
import { NavigationItem } from '@component/Navigation/Navigation.contracts'
import { SetType } from '@module/sets/contracts'

export interface DefaultRoute {
  path?: string
  meta: { permissions: Permission[] }
  name: string
}

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export const defaultRoutesConfig: DefaultRoute[] = [
  // TODO dashboard in this place
  {
    path: '/media/list',
    name: 'media.list',
    meta: { permissions: [Permission.ViewMedia] }
  },
  {
    path: '/users',
    name: 'users.list',
    meta: {
      permissions: [Permission.ViewUsers]
    }
  },
  {
    path: '/users/roles',
    name: 'users.roles.list',
    meta: {
      permissions: [Permission.ViewRoles]
    }
  }
]

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const navigation: NavigationItem[] = [
  {
    icon: 'fa fa-fw fa-tachometer-alt',
    label: 'Dashboard',
    route: { name: 'root.dashboard' },
    permissions: [
      Permission.ViewContents
    ]
  },
  {
    icon: 'fa fa-fw fa-project-diagram',
    label: 'Strony',
    children: [
      {
        label: 'Dodaj nową',
        route: { name: 'content.pages.create' },
        permissions: [
          Permission.CreateContents
        ]
      },
      {
        label: 'Przeglądaj strony',
        route: { name: 'content.pages.list' },
        permissions: [
          Permission.ViewContents
        ]
      },
      {
        label: 'Do akceptacji',
        route: { name: 'content.acceptance.list', params: { contentType: 'page' } },
        permissions: [
          Permission.AcceptContents
        ]
      }
    ]
  },
  {
    icon: 'fa fa-2x fa-folder-open',
    label: 'Listy',
    children: [
      {
        label: 'Listy ofert pracy',
        route: { name: 'sets.list', params: { type: String(SetType.JobOffers) } },
        permissions: [
          Permission.ViewJobsFeed
        ]
      },
      {
        label: 'Listy postów',
        route: { name: 'sets.list', params: { type: String(SetType.BlogPosts) } },
        permissions: [
          Permission.ViewBlogPostsFeed
        ]
      },
      {
        label: 'Listy postów z social mediów',
        route: { name: 'sets.list', params: { type: String(SetType.SocialPosts) } },
        permissions: [
          Permission.ViewSocialPostsFeed
        ]
      },
      {
        label: 'Listy pytań',
        route: { name: 'sets.list', params: { type: String(SetType.Question) } },
        permissions: [
          Permission.ViewQuestion
        ]
      }
    ]
  },
  {
    icon: 'fa fa-camera',
    label: 'Media',
    children: [
      {
        label: 'Katalog Plików',
        route: { name: 'media.list' },
        permissions: [
          Permission.ViewMedia
        ]
      },
      {
        label: 'Pliki oczekujące',
        route: { name: 'media.waiting-list' },
        permissions: [
          Permission.ViewMedia
        ]
      },
      {
        label: 'Galerie',
        route: { name: 'media.gallery.list' },
        permissions: [
          Permission.ViewMedia
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-users',
    label: 'Użytkownicy',
    children: [
      {
        label: 'Lista użytkowników',
        route: { name: 'users.list' },
        permissions: [
          Permission.ViewUsers
        ]
      },
      {
        label: 'Zarządzanie rolami',
        route: { name: 'users.roles.list' },
        permissions: [
          Permission.ViewRoles
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-cogs',
    label: 'Ustawienia',
    children: [
      {
        label: 'Nawigacja',
        route: { name: 'settings.navigation' },
        permissions: [
          Permission.ViewNavigation
        ]
      },
      {
        label: 'Layouty',
        route: { name: 'layouts.layouts.list' },
        permissions: [
          Permission.ViewLayout
        ]
      },
      {
        label: 'Wszystkie ustawienia',
        route: { name: 'settings.config.list' },
        permissions: [
          Permission.ViewSettings
        ]
      }
    ]
  }
]
