// Copyright © 2021 Move Closer

import { ICollection, QueryParams } from '@movecloser/front-core'

import { Answer } from '../../models'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const QuestionsRepositoryType = Symbol.for('IQuestionsRepository')

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export interface IQuestionsRepository {
  /**
   * Fetches the answers for question from the API.
   *
   * @param query - Query params entered by the User.
   */
  load (id: number, query?: QueryParams): Promise<ICollection<Answer>>
  addAnswer (id: number, payload: AnswerFormData) : Promise<void>
  vote (id: number, vote: number) : Promise<{ message: string; data: { vote: number } }>
  edit (id: number, content: string) : Promise<void>
  publish (id: number) : Promise<void>
  unpublish (id: number) : Promise<void>
}

export interface AnswerFormData {
  /**
   * Content of the answer.
   */
  content: string

  /**
   * User's nickname.
   */
  nickname: string

  /**
   * User's age.
   */
  age?: number
}

export interface VoteData {
  message: string
  data: { vote: number }
}
