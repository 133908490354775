












































































import { Component, Prop, PropSync } from 'vue-property-decorator'
import { padStart } from 'lodash'

import { Answer } from '../../../../models'

import { AbstractModuleUiPresentation } from '../../_abstract'
import { QuestionModuleContent } from '../Question.contracts'
import { VoteData } from '@/shared/modules/src/repositories/questions'

/**
 * Presentational component for the `QuestionModuleUi`.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Component<QuestionModuleUiPresentation>({
  name: 'QuestionModuleUiPresentation',
  components: {
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../atoms/UiHeading/UiHeading.vue'
    ),
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../atoms/UiMarkdown/UiMarkdown.vue'
    ),
    QuestionForm: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../molecules/QuestionForm/QuestionForm.vue'
    ),
    Pagination: () => import(
      /* webpackChunkName: "molecules/Pagination" */
      '../../../molecules/Pagination/Pagination.vue'
    ),
    Like: () => import(
      /* webpackChunkName: "icons/Like" */
      '../../../icons/Like.vue'
    ),
    Dislike: () => import(
      /* webpackChunkName: "icons/Dislike" */
      '../../../icons/Dislike.vue'
    ),
    ArrowIcon: () => import(
      /* webpackChunkName: "icons/ArrowIcon" */
      '../../../icons/ArrowIcon.vue'
    )
  },
  watch: {
    answers: {
      handler (newAnswers) {
        this.answersCopy = [...newAnswers]
      },
      immediate: true,
      deep: true
    }
  }
})
export class QuestionModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * @see QuestionModuleContent.headingContent
   */
   @Prop({ type: String, required: false })
  public readonly headingContent?: QuestionModuleContent['headingContent']

  /**
   * @see QuestionModuleContent.headingLevel
   */
  @Prop({ type: Number, required: false })
  public readonly headingLevel?: QuestionModuleContent['headingLevel']

  /**
   * Array of answers to render.
   */
  @Prop({ type: Array, required: true })
  public readonly answers!: Answer[]

  /**
   * Currently-active page.
   */
   @PropSync('currentPage', { type: Number, required: false })
  public _currentPage?: number

  @Prop({ type: Number, required: true })
  public readonly total!: number

  /**
   * Total number of elements per page.
   */
  @Prop({ type: Number, required: false, default: 10 })
  public readonly perPage!: number

  /**
   * Question id
   */
  @Prop({ type: Number, required: false, default: 10 })
  public readonly questionId!: number

  /**
   * Total number of elements per page.
   */
  @Prop({ type: Boolean })
  public readonly isLoading!: boolean

  private votedAnswers: Record<number, number> = {}
  public answersCopy: Answer[] = []
  public loadingVotes: Record<number, { like: boolean; dislike: boolean }> = {}
  public sortOptions = [
    {
      label: this.$t('modules.components.modules.Question.sort.date'),
      value: 'date'
    },
    {
      label: this.$t('modules.components.modules.Question.sort.like'),
      value: 'like'
    },
    {
      label: this.$t('modules.components.modules.Question.sort.dislike'),
      value: 'dislike'
    }
  ]

  public appliedSort = this.$route.query.sort ?? 'date'
  public isSortDropdownOpen: boolean = false

  /**
   * Determines whether the component has been provided with the correct `answers` prop.
   */
  public get hasAnswers (): boolean {
    return typeof this.answersCopy !== 'undefined' &&
      Array.isArray(this.answersCopy) &&
      this.answersCopy.length > 0
  }

  /**
   * Determines whether the search results can be paginated.
   */
  public get hasPagination (): boolean {
    return typeof this.total === 'number' && typeof this.perPage === 'number' &&
      this.totalPages > 1
  }

  /**
   * Calculate total numbers of pages.
   */
  public get totalPages (): number {
    return Math.ceil(this.total / this.perPage)
  }

  /**
   * Determines whether the component has been provided with the correct `headingContent` @Prop.
   */
  public get hasHeadingContent (): boolean {
    return typeof this.headingContent === 'string' && this.headingContent.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `headingLevel` @Prop.
   */
  public get hasHeadingLevel (): boolean {
    return typeof this.headingLevel === 'number' && this.headingLevel >= 1 && this.headingLevel <= 6
  }

  public formatDate (dateStr: string): string {
    const date = new Date(dateStr)
    const day = padStart(date.getDate().toString(), 2, '0')
    const month = padStart((date.getMonth() + 1).toString(), 2, '0')
    const year = date.getFullYear().toString()

    return `${day}.${month}.${year}` // DD.MM.YYYY
  }

  public async handleVote (answerId: number, vote: number): Promise<void> {
    this.$set(this.loadingVotes, answerId, { like: vote === 1, dislike: vote === -1 })
    this.$emit('submitVote', answerId, vote, (error: Error | null, voteData?: VoteData) => {
      this.$set(this.loadingVotes, answerId, { like: false, dislike: false })
      if (error) {
        console.error('Failed to submit vote:', error)
        return
      }

      if (voteData?.data.vote === 0) {
        this.removeVote(answerId, vote)
      } else {
        this.updateVote(answerId, vote, voteData?.data.vote ?? 0)
      }

      this.saveVotesToLocalStorage()
    })
  }

  private removeVote (answerId: number, vote: number): void {
    this.answersCopy = this.answersCopy.map(answer => {
      if (answer.id === answerId) {
        if (vote === 1) {
          answer.likeCount--
        } else {
          answer.dislikeCount--
        }
      }
      return answer
    })

    this.$delete(this.votedAnswers, answerId)
  }

  private updateVote (answerId: number, vote: number, newVote: number): void {
    this.answersCopy = this.answersCopy.map(answer => {
      if (answer.id === answerId) {
        if (vote === 1) {
          answer.likeCount++
          if (this.isVoted(answerId, -1)) {
            answer.dislikeCount--
          }
        } else {
          answer.dislikeCount++
          if (this.isVoted(answerId, 1)) {
            answer.likeCount--
          }
        }
      }
      return answer
    })

    this.$set(this.votedAnswers, answerId, newVote)
  }

  public isVoted (answerId: number, vote: number): boolean {
    return this.votedAnswers[answerId] === vote
  }

  private saveVotesToLocalStorage (): void {
    localStorage.setItem('votedAnswers', JSON.stringify(this.votedAnswers))
  }

  private loadVotesFromLocalStorage (): void {
    const votes = localStorage.getItem('votedAnswers')
    if (votes) {
      this.votedAnswers = JSON.parse(votes)
    }
  }

  public toggleSortDropdown (): void {
    this.isSortDropdownOpen = !this.isSortDropdownOpen
  }

  public handleQueryChange (value: string): void {
    this.isSortDropdownOpen = !this.isSortDropdownOpen
    this.appliedSort = value

    const queryParams: { [key: string]: string } = { ...this.$route.query, sort: value }

    if (value === 'like' || value === 'dislike') {
      queryParams.sort = value
    } else {
      delete queryParams.sort
    }

    this.$nextTick(() => {
      this.$router.replace({ query: queryParams })
    })
  }

  public getAgeWithSuffix = (age: number): string => {
    const defaultSuffix = this.$t('modules.components.modules.Question.yearsVariants.many')
    if (age % 100 >= 12 && age % 100 <= 14) return `${age} ${defaultSuffix}` // 12, 13, 14
    const lastDigit = age % 10
    if (age === 1) return `${age} ${this.$t('modules.components.modules.Question.yearsVariants.one')}`
    if (lastDigit >= 2 && lastDigit <= 4) return `${age} ${this.$t('modules.components.modules.Question.yearsVariants.few')}`
    return `${age} ${defaultSuffix}`
  }

  mounted (): void {
    this.loadVotesFromLocalStorage()
  }
}

export default QuestionModuleUiPresentation
