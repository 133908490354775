











import { Component, Watch } from 'vue-property-decorator'
import { QueryParams, ResourceActionFailed } from '@movecloser/front-core'

import { Answer } from '@/shared/modules/src/models/answer/contracts'
import { IQuestionsRepository, QuestionsRepositoryType, VoteData } from '@/shared/modules/src/repositories/questions'

import { log } from '../../../../support'
import { BOOTSTRAP_PAGINATION_PROPS } from '../../../molecules/Pagination/Pagination.config'
import { AbstractModuleUi } from '../../_abstract'
import { QuestionModule } from '../Question.contracts'

/**
 * Container component for the `QuestionModuleUi`.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Component<QuestionModuleUi>({
  name: 'QuestionModuleUi',
  components: {
    QuestionModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/Question" */
      './Question.ui.presentation.vue'
    )
  }
})
export class QuestionModuleUi extends AbstractModuleUi<QuestionModule> {
  /**
   * @see QuestionModuleContent.headingContent
   */
  public get headingContent (): string | undefined {
    if (typeof this.data.content.heading === 'undefined') {
      return
    }

    return this.data.content.heading.text
  }

  public get query (): QueryParams {
    return this.$route.query as QueryParams
  }

  /**
   * Total number of search results.
   */
  public total: number = 0

  /**
   * Number of elements per pages.
   */
  public perPage: number = 0

  /**
   * Currently-active page of the answers results (pagination).
   */
  public currentPage: number = 0

  /**
   * Determines whether the component is performing any async actions at a given moment.
   */
  public isLoading: boolean = false

  /**
   * @see QuestionModuleContent.headingLevel
   */
  public get headingLevel (): number | undefined {
    if (typeof this.data.content.heading === 'undefined') {
      return
    }

    return this.data.content.heading.level
  }

  public get questionId (): number | undefined {
    return this.data.content.posts?.value
  }

  /**
   * Answers from `this.module.content` with all related data already resolved and ready to use.
   */
  public answers: Answer[] = []

  /**
   * Determines whether the component should be rendered.
   */
  public get shouldRender (): boolean {
    return this.hasContent && this.hasAnswers
  }

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    try {
      await this.loadAnswers()
    } catch (error) {
      log(error, 'error')
    }
  }

  /**
   * Fetches the question answers from the `RelatedService`.
   */
  private async loadAnswers (): Promise<void> {
    this.isLoading = true

    const perPage = BOOTSTRAP_PAGINATION_PROPS.perPage

    try {
      if (!this.questionId) {
        throw new Error('Question id must be defined!')
      }

      const answers = await this.questionsRepository.load(
        this.questionId, { perPage, ...this.query }
      )

      this.answers = answers

      // FIXME
      // @ts-expect-error - For some reason constructor new Collection add meta under `_meta` key
      // instead of `meta`
      const meta = answers._meta

      this.total = meta.total
      this.perPage = meta.per_page
      this.currentPage = Number(meta.current_page)
    } catch (error) {
      log(error, 'error')
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Handles the @update:currentPage event on the `<QuestionsModuleUiPresentation>`.
   *
   * @param value - Value emitted by the event.
   *
   * @see currentPage
   */
  public async onCurrentPageChange (value: number): Promise<void> {
    if (typeof value !== 'number') {
      return
    }

    this.currentPage = value

    if (value.toString() !== this.$route.query.page) {
      this.isLoading = true

      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: value.toString()
        }
      })

      this.isLoading = false
    }
  }

  /**
   * Determines whether the component has successfully fetched the question answers
   * from the `questionsRepository`.
   */
  private get hasAnswers (): boolean {
    return this.answers.length > 0
  }

  /**
   * An instance of the `QuestionsRepository`.
   */
  private get questionsRepository (): IQuestionsRepository {
    return this.resolveInjection<IQuestionsRepository>(QuestionsRepositoryType)
  }

  public async submitVote (
    answerId: number,
    vote: number,
    callback: (error: ResourceActionFailed | null, data?: VoteData) => void
  ): Promise<void> {
    if (!this.questionId) {
      return
    }
    try {
      const voteData = await this.questionsRepository.vote(answerId, vote)
      callback(null, voteData)
    } catch (error) {
      const typedError = error as ResourceActionFailed
      callback(typedError)
    }
  }

  @Watch('query')
  private onQueryChange (): void {
    this.loadAnswers()
  }
}

export default QuestionModuleUi
