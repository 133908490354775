// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

export const questionItemAdapterMap: MappingConfig = {
  position: 'position',
  locked: 'locked',
  id: 'id',
  data: {
    type: MappingTypes.Function,
    value: item => {
      return item
    }
  }
}
