// Copyright © 2022 Move Closer

import { MappingConfig } from '@movecloser/front-core'

import { RelatedType } from '../../../contracts'

import { AbstractTypeDriver } from './_abstract'
import { DescriptionOfAnswer, ResolvedAnswer } from '../../../models/answer'

/**
 * Driver for the `RelatedService` capable of resolving a set of question.
 *
 * @see QuestionModule
 * @see QuestionModuleForm
 * @see QuestionModuleUi
 * @see QuestionModuleUiPresentation
 *
 * @author Łukasz Beben <lukasz.beben@movecloser.pl>
 */
export class QuestionDriver extends AbstractTypeDriver<DescriptionOfAnswer, ResolvedAnswer> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = {}

  /**
   * @inheritDoc
   */
  protected childType: RelatedType = RelatedType.Question

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = [RelatedType.Question]

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.Question
}
