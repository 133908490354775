// Copyright © 2022 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { isPlainObject } from 'lodash'
import { Link } from '@movecloser/ui-core'
import { RawLocation } from 'vue-router'

import { isRelated } from '../services'
import { SourceType } from '../models'

import { LinkWithLabel } from './link'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface AnyDescription extends Description {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type Description = Record<string, unknown>

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface DescriptionOfLink extends Description, HasIdentifier {
  /**
   * TODO: Documentation.
   */
  title: string

  /**
   * TODO: Documentation.
   */
  url: string | RawLocation
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export interface DescriptionOfSet<Source = SourceType> extends Description {
  id: Identifier
  items: Identifier[]
  title: string
  totalItems: number
  type: Source
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export type LinkFromForm = UnresolvedLink | Link | null

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface HasIdentifier {
  id: Identifier
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type Identifier = number

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum Picker {
  BlogFeed = 'blogFeedPicker',
  File = 'filePicker',
  JobsFeed = 'jobsFeed',
  Link = 'linkPicker',
  Gallery = 'galleryPicker',
  SocialFeed = 'socialFeedPicker',
  Navigation = 'navigationPicker',
  Question = 'questionPicker',
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
export type PickerCallback = (
  picker: Picker,
  onSelection: PickerSelectionCallback,
  selected?: Related,
  options?: AnyObject
) => void

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Krzysztof Ustowski <krzysztof.ustowski@movecloser.pl>
 */
export interface Related<Type = RelatedType, Value = Identifier> {
  /**
   * Type of the related data.
   */
  type: Type

  /**
   * ID(s) of the related data.
   */
  value: Value
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface RelatedLink {
  label: string
  relatedLink: Related<RelatedType.Link>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type RelatedSet = Related<RelatedType.BlogPostsFeed> |
  Related<RelatedType.JobOffersFeed> |
  Related<RelatedType.DepartmentsFeed> | Related<RelatedType.Gallery>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export enum RelatedType {
  BlogPost = 'blogPost',
  BlogPostsFeed = 'blogFeed',
  Department = 'department',
  DepartmentsFeed = 'departmentsFeed',
  DepartmentsOptions = 'departments',
  File = 'file',
  Gallery = 'gallery',
  JobOffer = 'jobOffer',
  JobOffersFeed = 'jobsFeed',
  JobsModel = 'jobsModel',
  Link = 'link',
  Navigation = 'nav',
  RegionsOptions = 'region',
  SocialPost = 'socialPost',
  SocialPostsFeed = 'socialFeed',
  Embed = 'embed',
  Question = 'question'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type PickerSelectionCallback = (selected: never) => void

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ResolvedLink = LinkWithLabel

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface UnresolvedImage {
  alt?: string
  caption?: string
  image: Related<RelatedType.File> | null
  maxHeight?: number
  maxWidth?: number
  title?: string
}

/**
 * Helper type for defining the set of unresolved keys inside the given interface.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type UnresolvedKeysOf<T, Keys extends keyof T> = Keys

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type UnresolvedLink = Omit<Link, 'target'> & { relatedTarget: Related<RelatedType.Link> | null }

/**
 * Determines whether the passed-in object correctly implements the `UnresolvedLink` interface.
 *
 * @param subject - The object to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isUnresolvedLink = (subject: any): subject is UnresolvedLink => {
  if (!isPlainObject(subject)) {
    return false
  }

  return isRelated(subject.relatedTarget)
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface RelatedOption {
  /**
   * Option's unique identifier. Should be used in communication with the API.
   */
  id: number

  /**
   * Option's human-readable label.
   */
  name: string
  option: string
}
