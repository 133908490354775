// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const answerAdapterMap: MappingConfig = {
  content: 'content',
  nickname: 'nickname',
  age: 'age',
  fingerprint: 'fingerprint',
  'g-recaptcha-response': 'g-recaptcha-response'
}
