// Copyright © 2021 Move Closer

import {
  ContainerUi,
  ContainerForm,
  ModuleDriver,
  moduleDriverFactoryRegistry,
  moduleDriverFormComponentRegistry,
  moduleDriverUiComponentRegistry
} from '@modules'

import { ModuleComponentsRegistry, PageBuilderConfig } from '@component/PageBuilder'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
const moduleRegistry: ModuleComponentsRegistry = {
  [ModuleDriver.BlogFeed]: {
    image: require('@/assets/images/modules/wpisy-blogowe.svg'),
    label: 'moduleDrivers.blog_feed'
  },
  [ModuleDriver.Button]: {
    image: require('@/assets/images/modules/przycisk.svg'),
    label: 'moduleDrivers.button'
  },
  [ModuleDriver.Card]: {
    image: require('@/assets/images/modules/karta.svg'),
    label: 'moduleDrivers.card'
  },
  [ModuleDriver.Carousel]: {
    image: require('@/assets/images/modules/karuzela.svg'),
    label: 'moduleDrivers.carousel'
  },
  [ModuleDriver.ContactPerson]: {
    image: require('@/assets/images/modules/osoba-kontaktowa.svg'),
    label: 'moduleDrivers.contact_person'
  },
  [ModuleDriver.Departments]: {
    image: require('@/assets/images/modules/obszary-zatrudnienia.svg'),
    label: 'moduleDrivers.departments'
  },
  [ModuleDriver.Embed]: {
    image: require('@/assets/images/modules/osadzenie.svg'),
    label: 'moduleDrivers.embed'
  },
  [ModuleDriver.FloatingButton]: {
    image: require('@/assets/images/modules/plywajacy-przycisk.svg'),
    label: 'moduleDrivers.floating_button'
  },
  [ModuleDriver.Footer]: {
    image: require('@/assets/images/modules/footer.svg'),
    label: 'moduleDrivers.footer'
  },
  [ModuleDriver.GlobalSearchResults]: {
    image: require('@/assets/images/modules/wyniki-wyszukiwania-globalne.svg'),
    label: 'moduleDrivers.global_search_results'
  },
  [ModuleDriver.HashtagWall]: {
    image: require('@/assets/images/modules/hashtag-wall.svg'),
    label: 'moduleDrivers.hashtag_wall'
  },
  [ModuleDriver.Heading]: {
    image: require('@/assets/images/modules/naglowek.svg'),
    label: 'moduleDrivers.heading'
  },
  [ModuleDriver.Hero]: {
    image: require('@/assets/images/modules/hero.svg'),
    label: 'moduleDrivers.hero'
  },
  [ModuleDriver.Image]: {
    image: require('@/assets/images/modules/zdjecie.svg'),
    label: 'moduleDrivers.image'
  },
  [ModuleDriver.JobOffers]: {
    image: require('@/assets/images/modules/oferty-pracy.svg'),
    label: 'moduleDrivers.job_offers'
  },
  [ModuleDriver.Labels]: {
    image: require('@/assets/images/modules/etykiety.svg'),
    label: 'moduleDrivers.labels'
  },
  [ModuleDriver.Mosaic]: {
    image: require('@/assets/images/modules/mozaika.svg'),
    label: 'moduleDrivers.mosaic'
  },
  [ModuleDriver.Navbar]: {
    image: require('@/assets/images/modules/nav-bar.svg'),
    label: 'moduleDrivers.navbar'
  },
  [ModuleDriver.Newsletter]: {
    image: require('@/assets/images/modules/newsletter.svg'),
    label: 'moduleDrivers.newsletter'
  },
  [ModuleDriver.Quote]: {
    image: require('@/assets/images/modules/cytat.svg'),
    label: 'moduleDrivers.quote'
  },
  [ModuleDriver.Skiplinks]: {
    image: require('@/assets/images/modules/wyniki-wyszukiwania-ofert-pracy.svg'),
    label: 'moduleDrivers.skiplinks'
  },
  [ModuleDriver.SearchResults]: {
    image: require('@/assets/images/modules/wyniki-wyszukiwania-ofert-pracy.svg'),
    label: 'moduleDrivers.search_results'
  },
  [ModuleDriver.Stats]: {
    image: require('@/assets/images/modules/statystyki.svg'),
    label: 'moduleDrivers.stats'
  },
  [ModuleDriver.Tabs]: {
    image: require('@/assets/images/modules/zakladki.svg'),
    label: 'moduleDrivers.tabs'
  },
  [ModuleDriver.Text]: {
    image: require('@/assets/images/modules/tekst.svg'),
    label: 'moduleDrivers.text'
  },
  [ModuleDriver.Tile]: {
    image: require('@/assets/images/modules/kafelek.svg'),
    label: 'moduleDrivers.tile'
  },
  [ModuleDriver.Timeline]: {
    image: require('@/assets/images/modules/os-czasu.svg'),
    label: 'moduleDrivers.timeline'
  },
  [ModuleDriver.Question]: {
    image: require('@/assets/images/modules/wpisy-blogowe.svg'),
    label: 'moduleDrivers.question'
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const builder: PageBuilderConfig = {
  componentFactories: moduleDriverFactoryRegistry,
  containerComponent: ContainerUi,
  containerForm: ContainerForm,
  formComponents: moduleDriverFormComponentRegistry,
  moduleRegistry,
  uiComponents: moduleDriverUiComponentRegistry
}
