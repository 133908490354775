










































import { Component } from 'vue-property-decorator'

import { Inject } from '@modules'
import { IQuestionsRepository, QuestionsRepositoryType } from '@/shared/modules/src/repositories/questions/contracts'

import { AbstractSetItem } from './AbstractSetItem'
import { QuestionItemData } from '../../contracts'

@Component
export class QuestionSetItem extends AbstractSetItem<QuestionItemData> {
  @Inject(QuestionsRepositoryType)
  private questionsRepository!: IQuestionsRepository

  public content = this.data.content
  private editMode: boolean = false
  private isPublished: boolean = this.data.published

  public get isEditMode (): boolean {
    return this.editMode
  }

  public async handleEdit (): Promise<void> {
    if (!this.isEditMode) {
      this.editMode = true
      return
    }

    try {
      await this.questionsRepository.edit(this.data.id, this.content)
      this.editMode = false
    } catch (error) {
      console.error(error)
    }
  }

  public handleCancel (): void {
    this.content = this.data.content
    this.editMode = false
  }

  public async handlePublish (): Promise<void> {
    await this.questionsRepository.publish(this.data.id)
    this.isPublished = true
  }

  public async handleUnpublish (): Promise<void> {
    await this.questionsRepository.unpublish(this.data.id)
    this.isPublished = false
  }
}

export default QuestionSetItem
