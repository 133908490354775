// Copyright © 2021 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { Picker } from '@/shared/modules'

import AddSetItemModal from '../components/AddSetItemModal.vue'
import BlogPostPickerModal from '../components/SetPicker/BlogPostsPickerModal'
import OffersPickerModal from '../components/SetPicker/OffersPickerModal'
import ResetListItemsModal from '../components/ResetListItemsModal.vue'
import SocialMediaPickerModal from '../components/SetPicker/SocialMediaPickerModal'
import QuestionPickerModal from '../components/SetPicker/QuestionPickerModal'

export enum SetModal {
  AddSetItem = 'addSetItem',
  ResetListItemsModal = 'resetListItemsModal'
}

/**
 * Registry of available modals.
 */
export const setModals: ModalRegistry<VueConstructor> = {
  [SetModal.AddSetItem]: AddSetItemModal,
  [SetModal.ResetListItemsModal]: ResetListItemsModal,

  [Picker.BlogFeed]: BlogPostPickerModal,
  [Picker.JobsFeed]: OffersPickerModal,
  [Picker.SocialFeed]: SocialMediaPickerModal,
  [Picker.Question]: QuestionPickerModal
}
