// Copyright © 2021 Move Closer

/**
 * Available type of set items source
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 */
export enum SourceType {
  BlogPostsFeed = 'blogFeed',
  Department = 'department',
  JobOffersFeed = 'jobsFeed',
  Image = 'imageFile',
  PostCategory = 'postCategory',
  SocialFeed = 'socialFeed',
  Question = 'question'
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum SetItemsCountSelector {
  Three = '3',
  Six = '6',
  Nine = '9'
}
