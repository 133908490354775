// Copyright © 2021 Move Closer

import {
  Collection,
  ICollection,
  Injectable,
  IResponse, mapCollection,
  QueryParams,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { IAnswerModel, Answer, answerAdapterMap } from '../../models'

import { AnswerFormData, IQuestionsRepository, VoteData } from './contracts'

/**
 * Repository capable of fetching the anwers for question from the API.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Injectable()
export class QuestionsRepository extends Repository<Answer, IAnswerModel> implements IQuestionsRepository {
  /**
   * @inheritDoc
   */
  protected map = answerAdapterMap

  /**
   * @inheritDoc
   */
  protected useAdapter = true

  /**
   * @inheritDoc
   */
  public async load (id: number, query: QueryParams = {}): Promise<ICollection<Answer>> {
    const response: IResponse = await this.connector.call(
      'questions', 'getAnswers', { id: id }, { ...query }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        response.status,
        { ...response.errors?.errors, ...response.errors?.data }
      )
    }

    return new Collection(mapCollection<Answer>(response.data.data, this.map), response.data.meta)
  }

  public async addAnswer (id: number, payload: AnswerFormData): Promise<void> {
    const response: IResponse = await this.connector.call(
      'questions', 'addAnswer', { id: id }, { ...payload, }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        response.status,
        { ...response.errors?.errors, ...response.errors?.data }
      )
    }

    return Promise.resolve()
  }

  public async vote (id: number, vote: number): Promise<VoteData> {
    const response: IResponse = await this.connector.call(
      'questions', 'vote', { id: id }, { ...{ vote: vote } }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        response.status,
        { ...response.errors?.errors, ...response.errors?.data }
      )
    }

    return response.data as VoteData
  }

  public async edit (id: number, content: string): Promise<void> {
    const response: IResponse = await this.connector.call(
      'answers', 'update', { id: id }, { ...{ content: content } }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        response.status,
        { ...response.errors?.errors, ...response.errors?.data }
      )
    }

    return Promise.resolve()
  }

  public async publish (id: number): Promise<void> {
    const response: IResponse = await this.connector.call(
      'answers', 'publish', { id: id }, {}
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        response.status,
        { ...response.errors?.errors, ...response.errors?.data }
      )
    }

    return Promise.resolve()
  }

  public async unpublish (id: number): Promise<void> {
    const response: IResponse = await this.connector.call(
      'answers', 'unpublish', { id: id }, {}
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        response.status,
        { ...response.errors?.errors, ...response.errors?.data }
      )
    }

    return Promise.resolve()
  }
}
