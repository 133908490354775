// Copyright © 2021 Move Closer

import { AbstractSetItem } from './set-item'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export class QuestionItem extends AbstractSetItem {
  public displayName () {
    return this._data.data.name
  }
}
