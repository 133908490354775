// Copyright © 2021 Move Closer

import { Permission } from '@module/auth/contracts/permissions'

import { SetType } from '@module/sets/contracts'
import { Route } from 'vue-router'

export const permissionsFactory = (to: Route): Permission[] => {
  const setType = to.params.type as unknown as SetType

  switch (setType) {
    case SetType.BlogPosts:
      if (to.name === 'sets.edit') {
        return [Permission.EditBlogPostsFeed]
      } else if (to.name === 'sets.create') {
        return [Permission.CreateBlogPostsFeed]
      } else if (to.name === 'sets.list') {
        return [Permission.ViewBlogPostsFeed]
      }
      return []
    case SetType.JobOffers:
      if (to.name === 'sets.edit') {
        return [Permission.EditJobsFeed]
      } else if (to.name === 'sets.create') {
        return [Permission.CreateJobsFeed]
      } else if (to.name === 'sets.list') {
        return [Permission.ViewJobsFeed]
      }
      return []
    case SetType.SocialPosts:
      if (to.name === 'sets.edit') {
        return [Permission.ViewSocialPostsFeed]
      } else if (to.name === 'sets.list') {
        return [Permission.ViewSocialPostsFeed]
      }
      return []
    case SetType.Question:
      if (to.name === 'sets.edit') {
        return [Permission.EditQuestion]
      } else if (to.name === 'sets.create') {
        return [Permission.CreateQuestion]
      } else if (to.name === 'sets.list') {
        return [Permission.ViewQuestion]
      }
      return []
    default:
      return []
  }
}
