// Copyright © 2022 Move Closer

import { ModuleConstructor, ModuleDriver, ModuleFactory } from '../../contracts'

import { ModuleVersions } from '../molecules'

import { blogFeedFactory } from './BlogFeed'
import { buttonFactory } from './Button'
import { cardFactory } from './Card'
import { carouselFactory, CarouselModuleVersion } from './Carousel'
import { contactPersonFactory } from './ContactPerson'
import { departmentsFactory, DepartmentsModuleVersion } from './Departments'
import { embedFactory, EmbedVersion } from './Embed'
import { floatingButtonFactory } from './FloatingButton'
import { footerFactory } from './Footer'
import { globalSearchResultsFactory } from './GlobalSearchResults'
import { hashtagWallFactory } from './HashtagWall'
import { headingFactory } from './Heading'
import { heroFactory, HeroModuleVersion } from './Hero'
import { imageFactory } from './Image'
import { jobOffersFactory, JobOffersModuleVersion } from './JobOffers'
import { labelsFactory } from './Labels'
import { mosaicFactory, MosaicModuleVersion } from './Mosaic'
import { navbarFactory } from './Navbar'
import { newsletterFactory } from './Newsletter'
import { questionFactory } from './Question'
import { quoteFactory } from './Quote'
import { searchResultsFactory } from './SearchResults'
import { skiplinksFactory } from './Skiplinks'
import { statsFactory, StatsModuleVersion } from './Stats'
import { tabsFactory, TabsModuleVersion } from './Tabs'
import { textFactory } from './Text'
import { tileFactory } from './Tile'
import { timelineFactory } from './Timeline'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type ModulesWithVersion = ModuleDriver.Stats | ModuleDriver.JobOffers | ModuleDriver.Carousel | ModuleDriver.Hero |
  ModuleDriver.Tabs | ModuleDriver.Departments | ModuleDriver.Embed | ModuleDriver.Mosaic

/**
 * This registry contains factories of module's structure.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const moduleDriverFactoryRegistry: Record<ModuleDriver, ModuleFactory> = {
  [ModuleDriver.BlogFeed]: blogFeedFactory,
  [ModuleDriver.Button]: buttonFactory,
  [ModuleDriver.Card]: cardFactory,
  [ModuleDriver.Carousel]: carouselFactory,
  [ModuleDriver.ContactPerson]: contactPersonFactory,
  [ModuleDriver.Departments]: departmentsFactory,
  [ModuleDriver.Embed]: embedFactory,
  [ModuleDriver.FloatingButton]: floatingButtonFactory,
  [ModuleDriver.Footer]: footerFactory,
  [ModuleDriver.GlobalSearchResults]: globalSearchResultsFactory,
  [ModuleDriver.HashtagWall]: hashtagWallFactory,
  [ModuleDriver.Heading]: headingFactory,
  [ModuleDriver.Hero]: heroFactory,
  [ModuleDriver.Image]: imageFactory,
  [ModuleDriver.JobOffers]: jobOffersFactory,
  [ModuleDriver.Labels]: labelsFactory,
  [ModuleDriver.Mosaic]: mosaicFactory,
  [ModuleDriver.Navbar]: navbarFactory,
  [ModuleDriver.Newsletter]: newsletterFactory,
  [ModuleDriver.Quote]: quoteFactory,
  [ModuleDriver.SearchResults]: searchResultsFactory,
  [ModuleDriver.Skiplinks]: skiplinksFactory,
  [ModuleDriver.Stats]: statsFactory,
  [ModuleDriver.Tabs]: tabsFactory,
  [ModuleDriver.Text]: textFactory,
  [ModuleDriver.Tile]: tileFactory,
  [ModuleDriver.Timeline]: timelineFactory,
  [ModuleDriver.Question]: questionFactory
}

/**
 * Registry that binds the `ModuleDriver` with the applicable
 * Vue component for rendering the given module's **form**.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const moduleDriverFormComponentRegistry: Record<ModuleDriver, ModuleConstructor> = {
  [ModuleDriver.BlogFeed]: () => import(
    /* webpackChunkName: "cms/BlogFeedModuleForm" */ './BlogFeed/form/BlogFeed.form.vue'
  ),
  [ModuleDriver.Button]: () => import(
    /* webpackChunkName: "cms/GeneralForm" */ './Button/form/Button.form.vue'
  ),
  [ModuleDriver.Card]: () => import(
    /* webpackChunkName: "cms/BlockForm" */ './Card/form/Card.form.vue'
  ),
  [ModuleDriver.Carousel]: () => import(
    /* webpackChunkName: "cms/Carousel" */ './Carousel/form/Carousel.form.vue'
  ),
  [ModuleDriver.ContactPerson]: () => import(
    /* webpackChunkName: "cms/ContactPersonModuleForm" */ './ContactPerson/form/ContactPerson.form.vue'
  ),
  [ModuleDriver.Departments]: () => import(
    /* webpackChunkName: "cms/DepartmentsModuleForm" */ './Departments/form/Departments.form.vue'
  ),
  [ModuleDriver.Embed]: () => import(
    /* webpackChunkName: "cms/Embed" */ './Embed/form/Embed.form.vue'
  ),
  [ModuleDriver.FloatingButton]: () => import(
    /* webpackChunkName: "cms/LayoutForm" */ './FloatingButton/form/FloatingButton.form.vue'
  ),
  [ModuleDriver.Footer]: () => import(
    /* webpackChunkName: "cms/LayoutForm" */ './Footer/form/Footer.form.vue'
  ),
  [ModuleDriver.GlobalSearchResults]: () => import(
    /* webpackChunkName: "cms/GlobalSearchResultsForm" */ './GlobalSearchResults/form/GlobalSearchResults.form.vue'
  ),
  [ModuleDriver.HashtagWall]: () => import(
    /* webpackChunkName: "cms/HashtagWallForm" */ './HashtagWall/form/HashtagWall.form.vue'
  ),
  [ModuleDriver.Heading]: () => import(
    /* webpackChunkName: "cms/GeneralForm" */ './Heading/form/Heading.form.vue'
  ),
  [ModuleDriver.Hero]: () => import(
    /* webpackChunkName: "cms/GeneralForm" */ './Hero/form/Hero.form.vue'
  ),
  [ModuleDriver.Image]: () => import(
    /* webpackChunkName: "cms/GeneralForm" */ './Image/form/Image.form.vue'
  ),
  [ModuleDriver.JobOffers]: () => import(
    /* webpackChunkName: "cms/JobOffersModuleForm" */ './JobOffers/form/JobOffers.form.vue'
  ),
  [ModuleDriver.Labels]: () => import(
    /* webpackChunkName: "cms/BlockForm" */ './Labels/form/Labels.form.vue'
  ),
  [ModuleDriver.Mosaic]: () => import(
    /* webpackChunkName: "cms/MosaicForm" */ './Mosaic/form/Mosaic.form.vue'
  ),
  [ModuleDriver.Navbar]: () => import(
    /* webpackChunkName: "cms/LayoutForm" */ './Navbar/form/Navbar.form.vue'
  ),
  [ModuleDriver.Newsletter]: () => import(
    /* webpackChunkName: "cms/NewsletterModuleForm" */ './Newsletter/form/Newsletter.form.vue'
  ),
  [ModuleDriver.Quote]: () => import(
    /* webpackChunkName: "cms/BlockForm" */ './Quote/form/Quote.form.vue'
  ),
  [ModuleDriver.SearchResults]: () => import(
    /* webpackChunkName: "cms/SearchResultsModuleForm" */ './SearchResults/form/SearchResults.form.vue'
  ),
  [ModuleDriver.Skiplinks]: () => import(
    /* webpackChunkName: "cms/LayoutForm" */ './Skiplinks/form/Skiplinks.form.vue'
  ),
  [ModuleDriver.Stats]: () => import(
    /* webpackChunkName: "cms/BlockForm" */ './Stats/form/Stats.form.vue'
  ),
  [ModuleDriver.Tabs]: () => import(
    /* webpackChunkName: "cms/TabsModuleForm" */ './Tabs/form/Tabs.form.vue'
  ),
  [ModuleDriver.Text]: () => import(
    /* webpackChunkName: "cms/GeneralForm" */ './Text/form/Text.form.vue'
  ),
  [ModuleDriver.Tile]: () => import(
    /* webpackChunkName: "cms/BlockForm" */ './Tile/form/Tile.form.vue'
  ),
  [ModuleDriver.Timeline]: () => import(
    /* webpackChunkName: "cms/TimelineModuleForm" */ './Timeline/form/Timeline.form.vue'
  ),
  [ModuleDriver.Question]: () => import(
    /* webpackChunkName: "cms/Question" */ './Question/form/Question.form.vue'
  )
}

/**
 * Registry that binds the `ModuleDriver` with the applicable
 * Vue component for rendering the given module's **UI**.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const moduleDriverUiComponentRegistry: Record<ModuleDriver, ModuleConstructor> = {
  [ModuleDriver.BlogFeed]: () => import(
    /* webpackChunkName: "modules/BlogFeed" */ './BlogFeed/ui/BlogFeed.ui.vue'
  ),
  [ModuleDriver.Button]: () => import(
    /* webpackChunkName: "modules/shared" */ './Button/ui/Button.ui.vue'
  ),
  [ModuleDriver.Card]: () => import(
    /* webpackChunkName: "modules/shared" */ './Card/ui/Card.ui.vue'
  ),
  [ModuleDriver.Carousel]: () => import(
    /* webpackChunkName: "modules/Carousel" */ './Carousel/ui/Carousel.ui.vue'
  ),
  [ModuleDriver.Embed]: () => import(
    /* webpackChunkName: "modules/Embed" */ './Embed/ui/Embed.ui.vue'
  ),
  [ModuleDriver.ContactPerson]: () => import(
    /* webpackChunkName: "modules/ContactPerson" */ './ContactPerson/ui/ContactPerson.ui.vue'
  ),
  [ModuleDriver.Departments]: () => import(
    /* webpackChunkName: "modules/Departments" */ './Departments/ui/Departments.ui.vue'
  ),
  [ModuleDriver.FloatingButton]: () => import(
    /* webpackChunkName: "frame" */ './FloatingButton/ui/FloatingButton.ui.vue'
  ),
  [ModuleDriver.Footer]: () => import(
    /* webpackChunkName: "frame" */ './Footer/ui/Footer.ui.vue'
  ),
  [ModuleDriver.GlobalSearchResults]: () => import(
    /* webpackChunkName: "modules/GlobalSearchResults" */ './GlobalSearchResults/ui/GlobalSearchResults.ui.vue'
  ),
  [ModuleDriver.HashtagWall]: () => import(
    /* webpackChunkName: "modules/HashtagWall" */ './HashtagWall/ui/HashtagWall.ui.vue'
  ),
  [ModuleDriver.Heading]: () => import(
    /* webpackChunkName: "modules/shared" */ './Heading/ui/Heading.ui.vue'
  ),
  [ModuleDriver.Hero]: () => import(
    /* webpackChunkName: "frame" */ './Hero/ui/Hero.ui.vue'
  ),
  [ModuleDriver.Image]: () => import(
    /* webpackChunkName: "modules/shared" */ './Image/ui/Image.ui.vue'
  ),
  [ModuleDriver.JobOffers]: () => import(
    /* webpackChunkName: "modules/shared" */ './JobOffers/ui/JobOffers.ui.vue'
  ),
  [ModuleDriver.Labels]: () => import(
    /* webpackChunkName: "modules/Labels" */ './Labels/ui/Labels.ui.vue'
  ),
  [ModuleDriver.Mosaic]: () => import(
    /* webpackChunkName: "modules/Mosaic" */ './Mosaic/ui/Mosaic.ui.vue'
  ),
  [ModuleDriver.Navbar]: () => import(
    /* webpackChunkName: "frame" */ './Navbar/ui/Navbar.ui.vue'
  ),
  [ModuleDriver.Newsletter]: () => import(
    /* webpackChunkName: "modules/Newsletter" */ './Newsletter/ui/Newsletter.ui.vue'
  ),
  [ModuleDriver.Quote]: () => import(
    /* webpackChunkName: "modules/Quote" */ './Quote/ui/Quote.ui.vue'
  ),
  [ModuleDriver.SearchResults]: () => import(
    /* webpackChunkName: "modules/SearchResults" */ './SearchResults/ui/SearchResults.ui.vue'
  ),
  [ModuleDriver.Skiplinks]: () => import(
    /* webpackChunkName: "frame" */ './Skiplinks/ui/Skiplinks.ui.vue'
  ),
  [ModuleDriver.Stats]: () => import(
    /* webpackChunkName: "modules/Stats" */ './Stats/ui/Stats.ui.vue'
  ),
  [ModuleDriver.Tabs]: () => import(
    /* webpackChunkName: "modules/shared" */ './Tabs/ui/Tabs.ui.vue'
  ),
  [ModuleDriver.Text]: () => import(
    /* webpackChunkName: "modules/shared" */ './Text/ui/Text.ui.vue'
  ),
  [ModuleDriver.Tile]: () => import(
    /* webpackChunkName: "modules/shared" */ './Tile/ui/Tile.ui.vue'
  ),
  [ModuleDriver.Timeline]: () => import(
    /* webpackChunkName: "modules/Timeline" */ './Timeline/ui/Timeline.ui.vue'
  ),
  [ModuleDriver.Question]: () => import(
    /* webpackChunkName: "modules/Question" */ './Question/ui/Question.ui.vue'
  ),
}

/**
 * Registry of modules with its version maps.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const moduleDriverVersionsRegistry: ModuleVersions<ModulesWithVersion> = {
  [ModuleDriver.Carousel]: CarouselModuleVersion,
  [ModuleDriver.Departments]: DepartmentsModuleVersion,
  [ModuleDriver.Embed]: EmbedVersion,
  [ModuleDriver.Hero]: HeroModuleVersion,
  [ModuleDriver.JobOffers]: JobOffersModuleVersion,
  [ModuleDriver.Stats]: StatsModuleVersion,
  [ModuleDriver.Tabs]: TabsModuleVersion,
  [ModuleDriver.Mosaic]: MosaicModuleVersion
}
