// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { QuestionModule, QuestionModuleContent } from './Question.contracts'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const questionContentFactory = (): QuestionModuleContent => ({
  heading: undefined,
  posts: null,
})

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const questionFactory: ModuleFactory<QuestionModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: false,
      rowSpan: 3
    }
  },
  createModule (): ModuleBase<QuestionModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: questionContentFactory(),
      driver: ModuleDriver.Question
    }
  }
}
