// Copyright © 2021 Move Closer

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'
import { Permission } from '@module/auth/contracts/permissions'

/**
 * JobFeeds
 */
export const canCreateJobsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateJobsFeed)
}

export const canViewJobsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewJobsFeed)
}

export const canDeleteJobsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteJobsFeed)
}

export const canEditJobsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditJobsFeed)
}

/**
 * Blog posts feed
 */
export const canCreateBlogPostsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateBlogPostsFeed)
}

export const canViewBlogPostsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewBlogPostsFeed)
}

export const canDeleteBlogPostsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteBlogPostsFeed)
}

export const canEditBlogPostsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditBlogPostsFeed)
}

// TODO - finish when API ready
/**
 * Social posts feed
 */
export const canCreateSocialPostsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  // return user.canPerform(domain, Permission.CreateSocialPostsFeed)
  return true
}

export const canViewSocialPostsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  // return user.canPerform(domain, Permission.ViewSocialPostsFeed)
  return true
}

export const canDeleteSocialPostsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  // return user.canPerform(domain, Permission.DeleteSocialPostsFeed)
  return true
}

export const canEditSocialPostsFeed = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  // return user.canPerform(domain, Permission.EditSocialPostsFeed)
  return true
}

/**
 * Question
 */
export const canViewQuestion = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewQuestion)
}

export const canCreateQuestion = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateQuestion)
}

export const canEditQuestion = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditQuestion)
}

export const canDeleteQuestion = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteQuestion)
}
